import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TextGroup from "../components/TextGroup"
import { PanelGreen, PanelWhite } from "../components/ContentPanel"
import Cta from "../components/Cta"

const IndexPage = () => (

  <Layout>

    <SEO title="Imagine the world to come" description="Reset is a chance for your voice to be heard, so that you can help shape a fairer, greener, future for the UK" />

    <PanelGreen>
      <TextGroup
        title="We invited you to imagine the world to come...<br />now let’s work together to make it happen"
        subtitle="<b>What will life look like after Covid?  We asked and over 57,000 people across the UK responded. They told us they wanted a fairer, kinder, greener, more connected Britain</b>"
        copy="
        <p>Supported by a group of MPs and peers from across the political spectrum, Reset set out to create a space for all of us to listen, reflect, plan and act – together – to bring about real and lasting change. We asked the public what they wanted life after Covid to look like, and created space for them to reflect and imagine together. </p>"
      />

    </PanelGreen>

    <PanelWhite>
      <TextGroup
        title="What we did"
        copy="<p>The All Party Parliamentary Group on the Green New Deal’s ‘Reset’ inquiry heard from over 57,000 people, including three nationally representative polls of 2,000 people; in-depth workshops with 108 people; evidence from 17 experts; in depth interviews with people who are digitally excluded; and written evidence from almost 40 organisations from the NFU to Nesta.</p>"
      />
      <br /><br />
      <TextGroup
        title="What we found"
        copy="
        <p>The public are way ahead of the Government on the scale of change they want, with ambitious ideas about the futureof work, public services, community, nature and local life.</p>
        <p>They want a fairer society and want to see bold government action on wages, work and housing to make that happen. There is widespread public support for the government to take steps to completely reshape everyday life in Britain, with more flexible working, a shorter working week and more vibrant neighbourhoods so people can play a meaningful role in their communities and act for the common good.</p>
        <p>Some of the most popular changes, including more green spaces, liveable streets, less traffic, more flexible working patterns and food grown closer to home, would also make a significant contribution to meeting the UK’s climate change targets. Now the APPG have published a policy package that shows how to deliver the changes the public told us they wanted to see.</p>"
      />
    </PanelWhite>

    <Cta />

  </Layout>
)

export default IndexPage
